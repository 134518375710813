import React, {Component} from "react";
import gear from './gear.svg';
import './App.css';

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="App-header">
                    <img src={gear} className="App-gear" alt="gear"/>
                    <h1>Página en construcción</h1>
                    <p>Por favor, vuelve en otro momento para que descubras lo que hemos preparado para ti.</p>
                </div>
            </div>
        );
    }
}

export default App;